import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiHintDirection } from '@taiga-ui/core/types';

import { HTMLStructure, HintTextType } from './types';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent {
  @Input() direction: TuiHintDirection = 'bottom-left';
  @Input() set value(text: string | null) {
    this.preparedHTMLStructure = (text ?? '').split('\n').reduce<HTMLStructure[]>((acc, line, _, endOfLineArray) => {
      line.split(' ').forEach((value, _, spaceArray) => {
        let type: HintTextType = 'text';

        if (value.includes('src="')) {
          type = 'svg';
          value = value.slice(value.indexOf('"') + 1, value.lastIndexOf('"'));
        }

        acc.push({ type, value });

        if (spaceArray.length > 1) {
          acc.push({ type: 'space' });
        }
      });

      if (endOfLineArray.length > 1) {
        acc.push({ type: 'new-line' });
      }

      return acc;
    }, []);
  }

  preparedHTMLStructure: HTMLStructure[] = [];

  onClick($event: MouseEvent) {
    $event.stopPropagation();
  }
}
