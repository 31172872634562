<button
  tuiIconButton
  class="button-close"
  appearance="tuiIconButton"
  icon="tuiIconCloseLarge"
  size="m"
  (click)="onClickCloseButton()"
></button>
<div class="wrapper">
  <ng-container [ngSwitch]="step">
    <div *ngSwitchCase="'authCompleted'" class="wrapper__item">
      <tui-marker-icon class="item__icon" src="tuiIconCheckLarge" mode="primary" size="m"></tui-marker-icon>
      <div class="item__title">{{ 'components.authDialog.labels.authCompletedTitle' | translate }}</div>
      <div class="item__description">{{ 'components.authDialog.labels.authCompletedDescription' | translate }}</div>
    </div>
    <div *ngSwitchCase="'checkProfile'" class="wrapper__item">
      <tui-marker-icon class="item__icon" src="tuiIconUser" mode="primary" size="m"></tui-marker-icon>
      <div class="item__title">{{ 'components.authDialog.labels.checkProfileTitle' | translate }}</div>
      <div class="item__description">
        {{ 'components.authDialog.labels.checkProfileDescription' | translate }}
      </div>
    </div>
  </ng-container>
</div>

<div class="buttons">
  <button
    *ngIf="step === 'authCompleted'"
    tuiButton
    class="buttons_main"
    type="button"
    appearance="flat"
    (click)="step = 'checkProfile'"
  >
    {{ 'common.buttons.next' | translate }}
  </button>

  <button
    *ngIf="step === 'checkProfile'"
    tuiButton
    class="buttons_main"
    type="button"
    appearance="flat"
    (click)="onClickMainButton()"
  >
    {{ 'components.authDialog.buttons.later' | translate }}
  </button>

  <button tuiButton type="button" class="buttons_secondary" appearance="primary" (click)="onClickSecondaryButton()">
    {{
      step === 'authCompleted'
        ? ('components.authDialog.buttons.goToBot' | translate)
        : ('components.authDialog.buttons.toProfile' | translate)
    }}
  </button>
</div>
