import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observer, lastValueFrom } from 'rxjs';
import { ChatMemberStatusUnion } from '@airgram/web';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { ChatModel, GroupUI } from '@src/models';
import { ObjectId } from '@src/types/id';
import { TranslateService } from '@ngx-translate/core';
import { FileReaderService } from '@src/core/services';

@Component({
  selector: 'app-search-groups',
  templateUrl: './search-groups.component.html',
  styleUrls: ['./search-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchGroupsComponent implements OnChanges {
  @Input() isChannel: boolean = false;
  @Input() excludeIds?: number[] = [];
  @Output() groupSelected: EventEmitter<GroupUI>;
  @ViewChild('confirmAttachDialog') confirmAttachDialog?: PolymorpheusContent<TuiDialogContext>;

  addGroups?: ChatModel[];
  addGroupSelectedId: ObjectId;
  searchQuery: string = '';
  searchGroups?: ChatModel[];

  constructor(
    private cdr: ChangeDetectorRef,
    private messengerService: TelegramMessengerService,
    private readonly fileReaderService: FileReaderService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    this.groupSelected = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isChannel) {
      this.getAddGroups();
    }
  }

  searchQueryChangeHandler(searchQuery: string) {
    if (!this.addGroups) {
      this.searchGroups = [];
      this.cdr.markForCheck();
      return;
    }

    if (searchQuery === '') {
      this.searchGroups = this.addGroups;
      this.cdr.markForCheck();
      return;
    }

    this.messengerService.api.searchChats(searchQuery).then(res => {
      if (res.totalCount === 0) {
        this.searchGroups = [];
        this.cdr.markForCheck();
        return;
      }

      const searchChatIds = res.chatIds;
      this.searchGroups = this.addGroups!.filter(group => searchChatIds.includes(group.id));
      this.cdr.markForCheck();
    });
  }

  async changeSelectedIdAddGroup(id?: ObjectId): Promise<void> {
    if (!id) return;

    if (this.confirmAttachDialog) {
      this.dialogService
        .open(this.confirmAttachDialog, {
          label: this.translateService.instant('components.searchGroups.dialogs.confirmationTitle'),
          size: 's',
          closeable: false,
          data: id,
        })
        .subscribe(() => {
          this.addGroupSelectedId = undefined;
        });
    }
  }

  async attachGroup(observer: Observer<void>): Promise<void> {
    const id = this.addGroupSelectedId as number;
    observer.complete();

    const selectedAddChat = this.addGroups?.find(chat => chat.id === id);
    let description: string | undefined;
    let inviteLink: string | undefined;
    let membersTelegramIds: number[] | undefined;
    let photo: File | undefined;

    if (!selectedAddChat || ['chatTypeBasicGroup', 'chatTypeSupergroup'].indexOf(selectedAddChat.type._) === -1) return;

    if (selectedAddChat.type._ === 'chatTypeBasicGroup') {
      const basicGroupId = selectedAddChat.type.basicGroupId;
      const basicGroupFullInfo = await this.messengerService.api.getBasicGroupFullInfo(basicGroupId);
      description = basicGroupFullInfo.description;
      inviteLink = basicGroupFullInfo.inviteLink?.inviteLink;

      membersTelegramIds = basicGroupFullInfo.members
        .map(member => {
          if (member.memberId._ === 'messageSenderUser') {
            return member.memberId.userId;
          }
          return;
        })
        .filter(id => id !== undefined) as number[];
    }

    if (selectedAddChat.type._ === 'chatTypeSupergroup') {
      const supergroupId = selectedAddChat.type.supergroupId;
      const supergroupFullInfo = await this.messengerService.api.getSupergroupFullInfo(supergroupId);
      description = supergroupFullInfo.description;
      inviteLink = supergroupFullInfo.inviteLink?.inviteLink;

      const chatMembers = await this.messengerService.api.getSupergroupMembers(supergroupId);
      membersTelegramIds = chatMembers.members
        .map(member => {
          if (member.memberId._ === 'messageSenderUser') {
            return member.memberId.userId;
          }
          return;
        })
        .filter(id => id !== undefined) as number[];
    }

    if (selectedAddChat.photo?.small) {
      const filePart = await lastValueFrom(this.fileReaderService.getFilePart(selectedAddChat.photo.small)); //TODO: Использовать большую фотографию (photo.big)
      if (!filePart) return;

      photo = new Blob([filePart.data]) as File;
    }

    const newGroup: GroupUI = {
      name: selectedAddChat.title,
      description,
      isChannel: this.isChannel,
      chatId: selectedAddChat.id,
      inviteLink, // TODO: проверить настройки использования inviteLink, если есть ограничения, то сгенерировать новый inviteLink
      membersTelegramIds,
      photo,
    };

    this.groupSelected.emit(newGroup);
  }

  private getAddGroups(): void {
    this.messengerService.getAllChatIds().then(chatIds => {
      this.addGroups = [];

      chatIds
        .filter(chatId => chatId < 0) // TODO: hack for groups
        .forEach(async chatId => {
          if (!this.excludeIds?.includes(chatId)) {
            const chatDetails = await this.messengerService.api.getChat(chatId);

            let chatMemberStatus: ChatMemberStatusUnion | undefined;
            if (chatDetails.type._ === 'chatTypeBasicGroup' && !this.isChannel) {
              const basicGroup = await this.messengerService.api.getBasicGroup(chatDetails.type.basicGroupId);
              chatMemberStatus = basicGroup.status;
            }

            if (chatDetails.type._ === 'chatTypeSupergroup' && this.isChannel === chatDetails.type.isChannel) {
              const supergroup = await this.messengerService.api.getSupergroup(chatDetails.type.supergroupId);
              chatMemberStatus = supergroup.status;
            }

            if (chatDetails.photo?.small) {
              this.fileReaderService.getFilePart(chatDetails.photo.small).subscribe(filePart => {
                if (!chatDetails.userPhotoPath) {
                  chatDetails.userPhotoPath = this.fileReaderService.getFileSource(filePart);
                  this.cdr.markForCheck();
                }
              });
            }

            if (chatMemberStatus?._ === 'chatMemberStatusCreator') {
              this.addGroups = [...this.addGroups!, chatDetails];
              this.searchGroups = this.addGroups;
              this.cdr.markForCheck();
            }
          }
        });
    });
  }
}
