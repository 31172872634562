import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AUTOCOMPLETE_TYPES } from '@src/constants';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { AddTestUser, TestService } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';
import { inputPhoneFormValidator } from '@src/app/modules/phone';
import { lastValueFrom } from 'rxjs';

type FormDataControls = { [key in keyof AddTestUser]: AbstractControl };

@Component({
  selector: 'app-moderator-page',
  templateUrl: './moderator-page.component.html',
  styleUrls: ['./moderator-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModeratorPageComponent extends ResizableBaseComponent {
  formMessageVisible: boolean = false;
  showLoader: boolean = false;
  errorMessage?: string;

  readonly form = new UntypedFormGroup(<FormDataControls>{
    firstName: new UntypedFormControl(undefined, Validators.required),
    middleName: new UntypedFormControl(undefined),
    lastName: new UntypedFormControl(undefined, Validators.required),
    phone: new UntypedFormControl(undefined, [Validators.required, inputPhoneFormValidator()]),
    email: new UntypedFormControl(undefined, Validators.required),
    code: new UntypedFormControl(undefined, Validators.required),
  });

  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private testService: TestService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  onClickLoginButton(): void {
    window.location.href = '/login';
  }

  onClickSendButton(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    this.errorMessage = undefined;
    this.form.disable();
    this.showLoader = true;
    this.cdr.detectChanges();

    lastValueFrom(this.testService.addTestUser(this.form.value))
      .then(() => {
        this.showLoader = false;
        this.formMessageVisible = true;
        this.cdr.markForCheck();
      })
      .catch(error => {
        this.errorMessage = error.error;
        this.form.enable();
        this.showLoader = false;
        this.cdr.markForCheck();
      });
  }
}
