import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const MONTHS = [
  { id: 1, name: marker('common.constants.months.january') },
  { id: 2, name: marker('common.constants.months.february') },
  { id: 3, name: marker('common.constants.months.march') },
  { id: 4, name: marker('common.constants.months.april') },
  { id: 5, name: marker('common.constants.months.may') },
  { id: 6, name: marker('common.constants.months.june') },
  { id: 7, name: marker('common.constants.months.july') },
  { id: 8, name: marker('common.constants.months.august') },
  { id: 9, name: marker('common.constants.months.september') },
  { id: 10, name: marker('common.constants.months.october') },
  { id: 11, name: marker('common.constants.months.november') },
  { id: 12, name: marker('common.constants.months.december') },
];

export const DELETE_AUTH_USER_CONFIRMATION_TEXT = marker(
  'components.userInfo.constants.deleteAuthUserConfirmationText',
);
