<tui-tooltip [direction]="direction" [content]="hintTemplate" (click)="onClick($event)"></tui-tooltip>

<ng-template #hintTemplate>
  <span>
    <ng-container *ngFor="let element of preparedHTMLStructure">
      <ng-container [ngSwitch]="element.type">
        <span *ngSwitchCase="'space'">{{ ' ' }}</span>

        <br *ngSwitchCase="'new-line'" />

        <span *ngSwitchCase="'text'">{{ element?.value }}</span>

        <tui-svg *ngSwitchCase="'svg'" [src]="element.value ?? ''"></tui-svg>
      </ng-container>
    </ng-container>
  </span>
</ng-template>
