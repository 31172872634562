/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Registration { 
    id?: string;
    unionName?: string;
    headOrgName?: string;
    fio?: string;
    email?: string;
    phone?: string;
    infoSource?: string;
    readyToSign?: boolean;
    readyToUse?: boolean;
    unionOrgsCount?: number;
    currentSoftware?: string;
    dateCreated?: string;
    status?: number;
}

