import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardButtonComponent extends ResizableBaseComponent {
  @Input() link = '';

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly clipboard: Clipboard,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  copyLinkToClipboard() {
    if (this.clipboard.copy(this.link)) {
      this.alertService.success(
        this.translateService.instant('components.copyToClipboardButton.allerts.successes.copyLink'),
      );
    }
  }
}
