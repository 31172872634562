/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные участника для регистрации.
 */
export interface RegistrationAdd { 
    /**
     * Название делового объединения
     */
    unionName?: string;
    /**
     * Название головной организации
     */
    headOrgName?: string;
    /**
     * ФИО
     */
    fio: string;
    /**
     * Email
     */
    email?: string;
    /**
     * Номер телефона
     */
    phone?: string;
    /**
     * Откуда узнали
     */
    infoSource?: string;
    /**
     * Готовы ли подписать договор о намерениях?
     */
    readyToSign?: boolean;
    /**
     * Готовы ли участвовать в опытной эксплуатации?
     */
    readyToUse?: boolean;
    /**
     * Сколько компаний входит в объединение?
     */
    unionOrgsCount?: number;
    /**
     * Какое программное обеспечение используете в настоящее время?
     */
    currentSoftware?: string;
}

