import { Component } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent {
  navigationOpen: boolean = true;
  expandedButtonOpacity: number = 0;

  toggleNavigationSize(): void {
    this.navigationOpen = !this.navigationOpen;

    if (!this.navigationOpen) {
      this.expandedButtonOpacity = 0;
    }
  }

  setOpacity(opacity: number): void {
    this.expandedButtonOpacity = opacity;
  }
}
