<div class="main">
  <div class="navigation navigation_size_{{ navigationOpen ? 'm' : 's' }}" (mouseover)="setOpacity(1)" (mouseout)="setOpacity(0)">
    <div class="button button_expanded" (click)="toggleNavigationSize()" [ngStyle]="{ opacity: expandedButtonOpacity }">
      <lucide-icon class="button__icon" [name]="navigationOpen ? 'chevron-left' : 'chevron-right'"></lucide-icon>
    </div>
    <app-navigation [isOpen]="navigationOpen"></app-navigation>
  </div>
  <div class="content content_size_{{ navigationOpen ? 's' : 'm' }}">
    <router-outlet></router-outlet>
  </div>
</div>
