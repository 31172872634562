import { Component, Input } from '@angular/core';
import { ChatMemberModel, OrganisationJobTitleUI, UserUI } from '@src/models';
import { OrganisationWithJobTitles } from '@src/api';
import { JobTitlesPipe } from '@src/app/shared/pipes';
import { getImageSrc } from '@src/utils';
import { ObjectId } from '@src/types/id';

import { ItemType } from '../model/item.model';

@Component({
  selector: 'app-list-item-users',
  templateUrl: './list-item-users.component.html',
  styleUrls: ['../styles/list-item.component.scss', './list-item-users.component.scss'],
})
export class ListItemUsersComponent {
  @Input() set item(data: ItemType | undefined) {
    this.data = data as ChatMemberModel | UserUI;
  }

  @Input() selectedId: ObjectId;
  @Input() loading?: boolean;

  data?: ChatMemberModel | UserUI;

  constructor(private jobTitlesPipe: JobTitlesPipe) {}

  get userAvatarUrl(): string {
    let photoIdPath;
    if (this.data && 'photoId' in this.data) {
      photoIdPath = getImageSrc(this.data.photoId);
    }
    return this.data?.photoPath ?? photoIdPath ?? '';
  }

  get jobTitles(): OrganisationWithJobTitles[] | OrganisationJobTitleUI[] {
    let organisationJobTitles: OrganisationWithJobTitles[] | OrganisationJobTitleUI[] = [];

    if (this.data) {
      // TODO: refactoring
      if ('jobTitlesInOrganisation' in this.data && this.data.jobTitlesInOrganisation) {
        organisationJobTitles = this.data.jobTitlesInOrganisation as OrganisationWithJobTitles[];
      } else if ('organisationJobTitles' in this.data && this.data.organisationJobTitles) {
        organisationJobTitles = this.jobTitlesPipe.transform(this.data.organisationJobTitles);
      }
    }

    const organisationWithJobTitles: OrganisationWithJobTitles[] = [];
    organisationJobTitles.forEach(organisationJobTitle => {
      if (
        !!organisationWithJobTitles.find(
          organisation => organisation.organisationId === organisationJobTitle.organisationId,
        )
      )
        return;
      organisationWithJobTitles.push(organisationJobTitle);
    });

    return organisationWithJobTitles ?? [];
  }
}
