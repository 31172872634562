<div class="cell-user" [routerLink]="['/association-users', user?.id]">
  <app-photo class="cell-user__photo" [photoId]="user?.photoId" size="s" [rounded]="true"></app-photo>

  <div
    *ngIf="statusVisible && user && user.decisionTypeName"
    class="cell-user__status"
    [class.cell-user__status_color-blue]="user.decisionTypeCode === 0 || user.decisionTypeCode === 1"
    [class.cell-user__status_color-green]="user.decisionTypeCode === 2 || user.decisionTypeCode === 4"
    [class.cell-user__status_color-red]="user.decisionTypeCode === 3"
    [title]="user.decisionTypeName"
  >
    {{ user.decisionTypeName }}
  </div>

  <div class="cell-user__name">{{ user?.fullName }}</div>

  <div *ngIf="!!user?.jobTitleDescription" class="cell-user__job-title">
    {{ user?.organisationName ? user?.organisationName + ': ' : '' }} {{ user?.jobTitleDescription }}
  </div>
</div>
