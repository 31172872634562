<div class="page">
  <div *ngIf="formMessageVisible" class="message">
    <div class="message__title tui-text_h5">{{ 'components.moderatorPage.labels.title' | translate }}</div>
    <button tuiButton class="message__button" [size]="tuiElementLargeSize" (click)="onClickLoginButton()">
      {{ 'components.moderatorPage.buttons.loginButton' | translate }}
    </button>
  </div>

  <div *ngIf="!formMessageVisible" class="user-info-view__wrap">
    <form class="user-info-view" [formGroup]="form">
      <div class="user-info-view__title tui-text_h3">{{ 'components.moderatorPage.labels.fillForm' | translate }}</div>

      <div class="user-info-view__block">
        <tui-input
          tuiAutoFocus
          tabindex="1"
          formControlName="lastName"
          
          [tuiTextfieldSize]="tuiElementLargeSize"
        >
          {{ 'components.moderatorPage.fields.lastName' | translate }}
          <span class="tui-required"></span>
        <input tuiTextfield [attr.autocomplete]="autocompleteTypes.lastName"/> </tui-input>

        <tui-input
          tabindex="2"
          formControlName="firstName"
          
          [tuiTextfieldSize]="tuiElementLargeSize"
        >
          {{ 'components.moderatorPage.fields.firstName' | translate }}
          <span class="tui-required"></span>
        <input tuiTextfield [attr.autocomplete]="autocompleteTypes.firstName"/> </tui-input>

        <tui-input
          tabindex="3"
          formControlName="middleName"
          
          [tuiTextfieldSize]="tuiElementLargeSize"
        >
          {{ 'components.moderatorPage.fields.middleName' | translate }}
        <input tuiTextfield [attr.autocomplete]="autocompleteTypes.middleName"/> </tui-input>

        <!-- FIXME: app-input-phone -->
        <app-input-phone tabindex="4" formControlName="phone" [size]="tuiElementLargeSize">
          {{ 'components.moderatorPage.fields.mobilePhone' | translate }}
          <span class="tui-required"></span>
        </app-input-phone>

        <tui-input
          tabindex="5"
          formControlName="email"
          
          
          [tuiTextfieldSize]="tuiElementLargeSize"
          [disabled]="showLoader"
        >
          {{ 'components.moderatorPage.fields.email' | translate }}
          <span class="tui-required"></span>
        <input tuiTextfield [attr.autocomplete]="autocompleteTypes.email"
type="email"/> </tui-input>

        <tui-input tabindex="6" formControlName="code" [tuiTextfieldSize]="tuiElementLargeSize" [readOnly]="showLoader">
          {{ 'components.moderatorPage.fields.code' | translate }}
          <span class="tui-required"></span>
        </tui-input>

        <div *ngIf="errorMessage" class="user-info-view__error">
          {{ errorMessage }}
        </div>
      </div>

      <div class="user-info-view__button">
        <button
          tuiButton
          tabindex="7"
          type="submit"
          [showLoader]="showLoader"
          [disabled]="showLoader"
          [size]="tuiElementLargeSize"
          (click)="onClickSendButton()"
        >
          {{ 'common.buttons.send' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
