export const environment = {
  emulateBotEnv: {
    enabled: false,
    key: '',
  },
  appVersion: `v ${require('../../package.json').version} (test)`,
  production: false,
  baseUrl: '',
  // TODO: брать настройки бота с бэка
  bots: {
    main: {
      id: 6589568078,
      username: 'TEST_UnionsAppBot',
    },
  },
};
