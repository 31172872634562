/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobTitleLoyaltyView } from './jobTitleLoyaltyView';


/**
 * Список должностей с описанием.
 */
export interface DiscountGroupLoyaltyView { 
    /**
     * Рейтинг.
     */
    discountRate?: number;
    /**
     * Условия.
     */
    discountOptions?: string;
    /**
     * Код.
     */
    discountCode?: string;
    /**
     * Признак необходимости генерации изображения QR-кода в интерфейсе.
     */
    generateQRCode: boolean;
    /**
     * Id загруженного файла изображения с QR-кодом.
     */
    discountQRCodeFileId?: string;
    /**
     * Текстовое представление скидки.
     */
    discountText?: string;
    /**
     * Размер скидки в абсолютных величинах.
     */
    discountAbsolute?: number;
    /**
     * Список должностей с описанием.
     */
    jobTitles: Array<JobTitleLoyaltyView>;
    /**
     * ID группы получателей скидки и её условия.
     */
    id: string;
}

