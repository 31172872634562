import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiInputModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiAutoFocusModule } from '@taiga-ui/cdk';
import { TranslateModule } from '@ngx-translate/core';
import { PhoneModule } from '@src/app/modules/phone';

import { ModeratorPageComponent } from './moderator-page.component';

@NgModule({
  declarations: [ModeratorPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    PhoneModule,
    ReactiveFormsModule,
    TuiAutoFocusModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TranslateModule,
  ],
})
export class ModeratorPageModule {}
