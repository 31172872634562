import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from '@src/app/modules/env';
import { TELEGRAM_API_CONFIG } from '@src/app/modules/telegram';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthDialogComponent implements OnInit {
  step: 'authCompleted' | 'checkProfile' = 'authCompleted';

  @Input() mainBotName?: string;
  @Input() loginByTelegram?: boolean;
  @Output() completed = new EventEmitter<void>();

  constructor(private readonly router: Router, public readonly env: EnvService) {}

  ngOnInit(): void {
    if (this.loginByTelegram) {
      this.step = 'checkProfile';
    }
  }

  onClickSecondaryButton() {
    if (this.step === 'authCompleted') {
      this.env.openLink(TELEGRAM_API_CONFIG.baseUrl + this.mainBotName);
    }

    if (this.step === 'checkProfile') {
      this.router.navigate(['settings', 'profile']);
      this.completed.emit();
    }
  }

  onClickMainButton() {
    if (this.step === 'checkProfile') {
      this.completed.emit();
    }
  }

  onClickCloseButton() {
    this.completed.emit();
  }
}
