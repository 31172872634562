import { Injectable } from '@angular/core';
import { UserService } from '@src/api';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom, throwError } from 'rxjs';
import { UserUI } from '@src/models';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { logger, normalizeUserDataForUI } from '@src/utils';

import { ROLE_PERMISSIONS_DEFAULT } from '../constants';
import { JwtTokenPayload, Role, RoleCode } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  /**
   * TODO: для обратной совместимости, чтобы UserService смог получить данные текущего пользователя.
   * Места, где идет чтение данных текущего пользователя, нужно брать из данного сервиса {AuthUserService#user}.
   */
  authUser$: BehaviorSubject<UserUI | undefined> = new BehaviorSubject<UserUI | undefined>(undefined);

  private userData?: UserUI;

  constructor(
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
  ) {
    logger('AuthUserService constructor');
  }

  get user() {
    return this.userData;
  }

  async init(payload: JwtTokenPayload): Promise<UserUI> {
    const user = await lastValueFrom(
      this.userService.getUserProfile().pipe(
        catchError(err => {
          return throwError(err);
        }),
        map(normalizeUserDataForUI),
      ),
    );

    user.parentOrganisationId = payload.HeadId;
    user.organisationId = payload.OrganisationId;

    // TODO: фильтр по дефолтной организации добавлен временно по задаче #10370
    user.roles = payload.Roles.filter(role => role.OrganisationId === payload.OrganisationId).map(
      role => role.RoleName,
    );
    if (user.roles) {
      user.permissions = this.getPermissions(user.roles);
      user.rolesWithPermissions = this.getRolesWithPermissions(user);
      user.permissionsForOrganisations = this.getPermissionsForOrganisations(payload.Roles);

      this.permissionsService.loadPermissions(user.permissions);
      this.rolesService.addRoles(user.rolesWithPermissions as Record<string, string[]>);
    }

    user.isFirstSignIn = (payload.isFirstSignIn as string).toLocaleLowerCase() === 'true';

    this.userData = user;
    this.authUser$.next(user);

    return user;
  }

  private getPermissions(roles: string[]) {
    const permissions = roles.reduce<string[]>((acc, role) => {
      if (ROLE_PERMISSIONS_DEFAULT[role as RoleCode]) {
        acc.push(...ROLE_PERMISSIONS_DEFAULT[role as RoleCode]);
      }

      return acc;
    }, []);

    return Array.from(new Set(permissions));
  }

  private getRolesWithPermissions(user: UserUI) {
    return user.roles?.reduce<Partial<Record<RoleCode, string[]>>>((acc, role) => {
      if (ROLE_PERMISSIONS_DEFAULT[role as RoleCode]) {
        acc[role as RoleCode] = [...ROLE_PERMISSIONS_DEFAULT[role as RoleCode]];

        if (role === RoleCode.AdminDO && user.organisationId) {
          acc[role]?.push(user.organisationId);
        }
      }

      return acc;
    }, {});
  }

  private getPermissionsForOrganisations(roles: Role[]) {
    return roles.reduce<Record<string, string[]>>((acc, role) => {
      const roleName = role.RoleName as RoleCode;

      if (ROLE_PERMISSIONS_DEFAULT[roleName] && role.OrganisationId) {
        acc[role.OrganisationId] = [...ROLE_PERMISSIONS_DEFAULT[roleName]];
      }

      return acc;
    }, {});
  }
}
